<template>
  <div class="table-responsive">
    <table class="tablesaw table-striped table-bordered" data-tablesaw-mode="swipe" data-tablesaw-sortable data-tablesaw-sortable-switch data-tablesaw-minimap data-tablesaw-mode-switch>
      <thead>
      <tr>
        <th :colspan="3 + columns.filter(c => c.show).length"
            style="font-weight: bold; font-size: 15px">
          <div class="row">
            <div class="col-12">
              {{ title }}

              <a class="ml-2 text-muted"
                 href="#"
                 @click.prevent="$refs.tableSettingsModal.show()">
                <i class="fa-solid fa-gear"></i>
              </a>

              <bulk-label-management
                  v-if="selectedCampaigns.length"
                  @updateLabels="syncLabels"
                  :campaigns="sortedCampaigns"
                  :selectedCampaignIds="selectedCampaigns" />

              <span class="text-muted ml-2" v-if="selectedCampaigns.length">
                |

                <a href="#"
                   v-if="sortedCampaigns.filter(c => selectedCampaigns.includes(c.id) && !c.influencer_name).length"
                   @click.prevent="changeBulkStatus(0)"
                   class="ml-2 text-muted"
                   title="Pause">
                  <i class="fa-solid fa-pause"></i>
                </a>

                <a href="#"
                   v-if="sortedCampaigns.filter(c => selectedCampaigns.includes(c.id) && !c.influencer_name).length"
                   @click.prevent="changeBulkStatus(1)"
                   class="ml-2 text-muted"
                   title="Enable">
                  <i class="fa-solid fa-play"></i>
                </a>

                <a href="#"
                   @click.prevent="removeCampaign(null)"
                   class="ml-2 text-muted"
                   title="Unlink">
                  <i class="fa-solid fa-link-slash"></i>
                </a>
              </span>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th>
          <input v-model="allSelected"

                 @change="checkAllSelected"
                 type="checkbox" />
        </th>

        <th ref="ttt">
          <resizable :active="['r']" :min-width="300">
            Amazon Campaign
          </resizable>
        </th>
        <th></th>
        <th @click="sortTable('spend')"
            v-if="columns.find(c => c.name === 'Spend').show">
          Spend
          <span v-if="this.table.sortColumn === 'spend'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th @click="sortTable('impressions')"
            v-if="columns.find(c => c.name === 'Impressions').show">
          Impressions
          <span v-if="this.table.sortColumn === 'impressions'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th @click="sortTable('clicks')"
            v-if="columns.find(c => c.name === 'Clicks').show">
          Clicks

          <span v-if="this.table.sortColumn === 'clicks'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th @click="sortTable('cpc')"
            v-if="columns.find(c => c.name === 'CPC').show">
          CPC

          <span v-if="this.table.sortColumn === 'cpc'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th v-if="columns.find(c => c.name === 'CTR').show">CTR</th>
        <th @click="sortTable('atc')"
            v-if="columns.find(c => c.name === 'Add To Cart').show">
          Add To Cart

          <span v-if="this.table.sortColumn === 'atc'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th @click="sortTable('total_purchase')"
            v-if="columns.find(c => c.name === 'Conversions').show">
          Conversions

          <span v-if="this.table.sortColumn === 'total_purchase'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th @click="sortTable('total_sale')"
            v-if="columns.find(c => c.name === 'Revenue').show">
          Revenue
          <span v-if="this.table.sortColumn === 'total_sale'">
            <i class="fas fa-sort-amount-down-alt"
               aria-hidden="true"
               v-if="this.table.sortOrder === 'asc'"></i>

            <i class="fas fa-sort-amount-down"
               aria-hidden="true" v-else></i>
          </span>
        </th>
        <th v-if="columns.find(c => c.name === 'ACoS').show">ACoS</th>
        <th v-if="columns.find(c => c.name === 'Brand Referral Bonus').show">Brand Referral Bonus</th>
        <th class="text-center"
            v-if="columns.find(c => c.name === 'Status').show">Status</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(campaign, i) in sortedCampaigns" :key="'campaign_'+i">
        <tr>
          <td style="width: 3%">
            <input v-model="selectedCampaigns"
                   :value="campaign.id"
                   type="checkbox">
          </td>
          <td>
            <router-link v-if="campaign.google_campaign_name"
                         :to="{name: 'keywords_google', params: {id: campaign.google_campaign, profileId: queryParams.profileId}}">
              {{ formatCampaignName(campaign.name) }}
            </router-link>

            <template v-else>{{ removeUnderscore(campaign.name) }}</template>

            <template v-if="campaign.labels && campaign.labels.length">
              <span class="campaign-label ml-1"
                    :key="'label_'+label.id"
                    :style="{backgroundColor: label.color}"
                    v-for="label in campaign.labels">{{ label.name }}</span>
            </template>
          </td>
          <td class="no-wrap text-right">
            <router-link class="mr-1" v-if="campaign.google_campaign_name" :to="{name: 'keywords_google', params: {id: campaign.google_campaign, profileId: queryParams.profileId}}">
              <img :src="baseUrl + 'images/keyword.png'" style="height: 17px">
            </router-link>

            <a v-if="campaign.fb_campaign_name"
               href="#"
               role="button"
               @click.prevent="getFacebookAdDetails(campaign)">
              <i class="text-blue fab fa-facebook tbl-social-icon"></i>
            </a>

            <a href="#"
               v-if="campaign.google_campaign_name"
               @click.prevent="getGoogleAdDetails(campaign)">
              <img :src="baseUrl + 'images/google.png'" style="height: 17px">
            </a>
            <a href="#"
               v-if="campaign.influencer_name"
               @click.prevent="getInfluencerAdDetails(campaign)">
              <img :src="baseUrl + 'images/infu.png'" style="height: 17px">
            </a>
          </td>
          <td class="no-wrap"
              v-if="columns.find(c => c.name === 'Spend').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>
              <span v-if="campaign.influencer_amount">{{ this.currentCurrency + ' '  + formatNumber(campaign.influencer_amount, 2) }}</span>
              <span v-else-if="campaign.influencer_percentage">{{ formatNumber(campaign.influencer_percentage, 2) }}%</span>
              <span v-else>{{ this.currentCurrency + ' ' + formatNumber(campaign.spend, 2) }}</span>
            </span>
          </td>
          <td v-if="columns.find(c => c.name === 'Impressions').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else-if="campaign.influencer_name">-</span>
            <span v-else>{{ formatNumber(campaign.impressions) }}</span>
          </td>
          <td v-if="columns.find(c => c.name === 'Clicks').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ formatNumber(campaign.clicks) }}</span>
          </td>
          <td class="no-wrap" v-if="columns.find(c => c.name === 'CPC').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ this.currentCurrency }}
              <span v-if="campaign.influencer_name">{{ campaign.clicks ? formatNumber(campaign.influencer_amount / campaign.clicks, 2) : '0.00' }}</span>
              <span v-else>{{ campaign.cpc ? formatNumber(campaign.cpc, 2) : '0.00' }}</span>
            </span>
          </td>
          <td v-if="columns.find(c => c.name === 'CTR').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else-if="campaign.influencer_name">-</span>
            <span v-else>{{ campaign.impressions ? formatNumber((campaign.clicks * 100 ) / campaign.impressions, 2) : '0.00' }}%</span>
          </td>
          <td v-if="columns.find(c => c.name === 'Add To Cart').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ formatNumber(campaign.atc) }}</span>
          </td>
          <td v-if="columns.find(c => c.name === 'Conversions').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ formatNumber(campaign.total_purchase) }}</span>
          </td>
          <td class="no-wrap"
              v-if="columns.find(c => c.name === 'Revenue').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ this.currentCurrency }} {{ formatNumber(campaign.total_sale, 2) }}</span>
          </td>
          <td v-if="columns.find(c => c.name === 'ACoS').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ formatNumber(calculateACoS(campaign.total_sale, campaign.spend, campaign.brb), 2) }}%</span>
          </td>
          <td class="no-wrap"
              v-if="columns.find(c => c.name === 'Brand Referral Bonus').show">
            <div class="spinner-border spinner-border-sm"
                 v-if="loadingMetrics"
                 role="status"></div>
            <span v-else>{{ this.currentCurrency }} {{ formatNumber(campaign.brb, 2) }}</span>
          </td>
          <td class="text-right"
              v-if="columns.find(c => c.name === 'Status').show">
            <div class="btn-group m-h-34" v-if="campaign.influencer_name">
              <button type="button" class="btn btn-sm w-80 btn-success">ACTIVE</button>
              <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split btn-success" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                <a class="dropdown-item"
                   v-if="account && account.role !== 3"
                   href="#"
                   @click.prevent="removeCampaign(campaign)">Unlink</a>
              </div>
            </div>
            <div class="btn-group m-h-34" v-if="campaign.status && campaign.google_campaign_name">
              <button type="button" class="btn btn-sm w-80" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]">{{ campaign.status.status == 'active' ? 'ENABLED' : campaign.status.status }}</button>
              <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                <a class="dropdown-item"
                   @click.prevent="changeGoogleStatus(2, campaign)"
                   href="#" >Enable</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                   @click.prevent="changeGoogleStatus(3, campaign)"
                   href="#">Pause</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="removeCampaign(campaign)">Unlink</a>
              </div>
            </div>
            <div class="btn-group m-h-34" v-if="campaign.status && campaign.fb_campaign_name">
              <button type="button" class="btn btn-sm w-80" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]">{{campaign.status.status }}</button>
              <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                <a class="dropdown-item"
                   @click.prevent="changeFacebookStatus('ACTIVE', campaign)"
                   href="#" >Active</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                   @click.prevent="changeFacebookStatus('PAUSED', campaign)"
                   href="#">Pause</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                   v-if="account && account.role !== 3"
                   href="#"
                   @click.prevent="removeCampaign(campaign)">Unlink</a>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <tr v-if="showSummary">
        <th colspan="3">Summary</th>
        <th class="no-wrap" v-if="columns.find(c => c.name === 'Spend').show">{{ currentCurrency + ' ' + formatNumber(totalSpend, 2) }}</th>
        <th v-if="columns.find(c => c.name === 'Impressions').show">{{ formatNumber(sortedCampaigns.reduce((a, b) => a + b.impressions, 0)) }}</th>
        <th v-if="columns.find(c => c.name === 'Clicks').show">{{ formatNumber(sortedCampaigns.reduce((a, b) => a + b.clicks, 0)) }}</th>
        <th v-if="columns.find(c => c.name === 'CPC').show">{{ currentCurrency + ' ' + formatNumber(averageCpc, 2) }}</th>
        <th v-if="columns.find(c => c.name === 'CTR').show">{{ formatNumber(averageCtr, 2) }}</th>
        <th v-if="columns.find(c => c.name === 'Add To Cart').show">{{ formatNumber(sortedCampaigns.reduce((a, b) => a + b.atc, 0)) }}</th>
        <th v-if="columns.find(c => c.name === 'Conversions').show">{{ formatNumber(sortedCampaigns.reduce((a, b) => a + b.total_purchase, 0)) }}</th>
        <th v-if="columns.find(c => c.name === 'Revenue').show">{{ currentCurrency + ' ' + formatNumber(totalSale, 2) }}</th>
        <th v-if="columns.find(c => c.name === 'ACoS').show">{{ formatNumber(calculateACoS(totalSale, totalSpend, totalBrb), 2) }}%</th>
        <th v-if="columns.find(c => c.name === 'Brand Referral Bonus').show">{{ currentCurrency + ' ' + formatNumber(totalBrb, 2) }}</th>
        <th></th>
      </tr>
      </tbody>
    </table>
  </div>

  <facebook-campaign-modal ref="facebookCampaignModal" />
  <google-campaign-modal ref="googleCampaignModal"
                         :id="'google_campaign_modal_'+id"
                         @labelUpdated="labelUpdated" />
  <influencer-campaign-modal :currentCurrencyCode="currentCurrencyCode"
                             :id="'influencer_campaign_modal_'+id"
                             @labelUpdated="labelUpdated"
                             :ref="'influencerCampaignModal_'+id" />
  <table-settings-modal ref="tableSettingsModal" />
</template>

<script>
import FacebookCampaignModal from "@/views/modals/FacebookCampaignModal.vue";
import GoogleCampaignModal from "@/views/modals/GoogleCampaignModal.vue";
import InfluencerCampaignModal from "@/views/modals/InfluencerCampaignModal.vue";
import TableSettingsModal from "@/components/dashboard/TableSettingsModal.vue";
import {mapGetters} from "vuex";
import Resizable from 'vue-resizable'
import BulkLabelManagement from "@/components/dashboard/BulkLabelManagement.vue";

export default {
  components: {BulkLabelManagement, InfluencerCampaignModal, Resizable,
    GoogleCampaignModal, FacebookCampaignModal, TableSettingsModal},
  props: ['campaigns', 'queryParams', 'currentCurrency', 'currentCurrencyCode',
    'loadingMetrics', 'showSummary', 'title', 'id'],
  data() {
    return {
      newColumns: [
        { label: 'Column 1', width: 100 },
      ],
      selectedCampaigns: [],
      allSelected: false,
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      },
    }
  },
  watch: {
    selectedCampaigns() {
      this.allSelected = this.sortedCampaigns.length === this.selectedCampaigns.length;
    },
  },
  computed: {
    ...mapGetters({
      columns: 'settings/getDashboardColumns',
      labels: 'getLabels',
      account: 'auth/getAccount',
      settings: 'settings/getSettings'
    }),
    sortedCampaigns() {
      let c = this.campaigns;

      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
    },
    totalBrb() {
      return this.sortedCampaigns.reduce((a, b) => a + b.brb, 0);
    },
    totalSale() {
      return this.sortedCampaigns.reduce((a, b) => a + b.total_sale, 0);
    },
    totalSpend() {
      let totalSpend = 0;

      this.sortedCampaigns.forEach(campaign => {
        if (campaign.influencer_amount) {
          totalSpend += campaign.influencer_amount;
        } else if (campaign.influencer_percentage) {
          totalSpend += 0;
        } else {
          totalSpend += campaign.spend;
        }
      });

      return totalSpend;
    },
    averageCpc() {
      let totalCpc = 0;
      let averageCpc = 0;

      if (this.sortedCampaigns.length) {
        this.sortedCampaigns.forEach(campaign => {
          if (campaign.influencer_name) {
            totalCpc += campaign.clicks ? (campaign.influencer_amount / campaign.clicks) : 0
          } else {
            totalCpc += campaign.cpc ? campaign.cpc : 0
          }
        });

        averageCpc = totalCpc / this.sortedCampaigns.length;
      }

      return averageCpc;
    },
    averageCtr() {
      let totalCtr = 0;
      let totalCampaign = 0;

      this.sortedCampaigns.forEach(campaign => {
        if (!campaign.influencer_name) {
          totalCampaign++;
          totalCtr += campaign.impressions ? ((campaign.clicks * 100 ) / campaign.impressions) : 0;
        }
      })

      if (totalCampaign) {
        return (totalCtr / totalCampaign).toFixed(2) + '%';
      }

      return '-';
    }
  },
  methods: {
    changeBulkStatus(active) {
      this.$swal({
        title: 'Are you sure want to '+(active ? 'active' : 'pause')+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/campaign-status', {
            ids: this.selectedCampaigns,
            status: active
          }).then(() => {
            setTimeout(() => {
              this.sortedCampaigns
                  .filter(c => this.selectedCampaigns.includes(c.id) && c.google_campaign_name)
                  .forEach(c => {
                    this.$emit('updateCampaignInfo', {
                      id: c.id,
                      status: this.getGoogleCampaignStatus(active ? 2 : 3)
                    });
                  });

              this.sortedCampaigns
                  .filter(c => this.selectedCampaigns.includes(c.id) && c.fb_campaign_name)
                  .forEach(c => {
                    this.$emit('updateCampaignInfo', {
                      id: c.id,
                      status: this.getFacebookCampaignStatus(active ? 'ACTIVE' : 'PAUSED')
                    });
                  });
            }, 100)

            // this.selectedCampaigns = this.selectedCampaigns.filter(c => !ids.includes(c))
            // this.$swal.fire(
            //     'Unlinked',
            //     'Campaign has been unlinked!',
            //     'success'
            // )
            // this.$emit('unlinkCampaign', ids)
          });
        }
      });
    },
    checkAllSelected() {
      if (this.allSelected) {
        this.selectedCampaigns = this.sortedCampaigns.map(d => d.id);
      } else {
        this.selectedCampaigns = [];
      }
    },
    labelUpdated(payload) {
      this.$emit('labelUpdated', payload);
    },
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    getFacebookAdDetails(campaign) {
      this.$refs.facebookCampaignModal.show(campaign.fb_campaign_id);
    },
    getGoogleAdDetails(campaign) {
      this.$refs.googleCampaignModal.show(campaign.google_campaign);
    },
    getInfluencerAdDetails(campaign){
      this.$refs['influencerCampaignModal_'+this.id].show(campaign.id);
    },
    changeGoogleStatus(status, campaign) {
      this.axios.post('/google/change/status', {
        status: status,
        customerId: campaign.google_account_id,
        campaignId: campaign.google_campaign
      })

      setTimeout(() => {
        this.$emit('updateCampaignInfo', {
          id: campaign.id,
          status: this.getGoogleCampaignStatus(status)
        });
      }, 100)
    },
    changeFacebookStatus(status, campaign) {
      this.axios.post('/facebook/change/status', {
        status: status,
        campaignId: campaign.fb_campaign_id
      })

      setTimeout(() => {
        this.$emit('updateCampaignInfo', {
          id: campaign.id,
          status: this.getFacebookCampaignStatus(status)
        });
      }, 100)
    },
    calculateACoS(purchase, spend, brb) {
      if (purchase && !isNaN(spend / purchase)) {

        if (this.showAcosAfterBrb)
          spend -= brb;

        return ((spend / purchase) === Infinity ? 0 : (spend / purchase) * 100);
      } else
        return 0;
    },
    removeCampaign(campaign = null) {
      let ids = this.selectedCampaigns;

      if (campaign) {
        ids = [campaign.id];
      }

      this.$swal({
        title: 'Are you sure want to unlink?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unlink it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/remove-campaign/', {
            ids
          }).then(() => {
            this.selectedCampaigns = this.selectedCampaigns.filter(c => !ids.includes(c))
            this.$swal.fire(
                'Unlinked',
                'Campaign has been unlinked!',
                'success'
            )
            this.$emit('unlinkCampaign', ids)
          });
        }
      });
    },
  }
}
</script>

<style>

.tablesaw tbody tr,
.tablesaw tbody tr td{
  height: 58px;
  min-height: 58px;
}

.m-h-34{
  min-height: 34px;
}

.tbl-social-icon {
  font-size: 17px;
  position: relative;
  top: 3px;
}

.tablesaw th {
  padding: 17px 0.7em;
}
.row.layout-spacing {
  margin: 0 -5px;
}
.row.layout-spacing {
  margin-bottom: 20px !important;
  margin-top: 10px;
}
.row.layout-spacing > div {
  padding: 0px 5px;
}

.w-80{
  width: 80px;
}

@media screen and (min-width: 1100px){
  .w-md-auto{
    width: auto !important
  }
}

</style>