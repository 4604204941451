<template>
  <div class="modal fade"
       :id="id"
       role="dialog"
       data-bs-focus="false"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-facebook-campaign-detailsLabel">Campaign Attributes </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="statbox widget box p-0" v-if="campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0" >
                  <tbody>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ removeUnderscore(campaign.amazon_campaign) }}</td>
                    <td rowspan="4"
                        class="text-center"
                        style="width: 20%">
                      <img style="height: 130px"
                           :src="campaign ? campaign.product_image : '/images/no-image1.png'">
                    </td>
                  </tr>
                  <tr>
                    <th>ASIN</th>
                    <td>{{ campaign.influencer_asin }}</td>
                  </tr>
                  <tr>
                    <th>Spend</th>
                    <td>
                      <div class="row" v-if="!editSpend">
                        <div class="col-md-10">
                          {{ amount }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editSpend = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-4">
                          <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                 :disabled="loading"
                                 type="text"
                                 placeholder="Amount"
                                 v-model="spendForm.amount">

                        </div>
                        <div class="col-md-4">
                          <input :class="['form-control form-control-sm', {'is-invalid': errorFor('percentage')}]"
                                 :disabled="loading"
                                 type="text"
                                 placeholder="Percentage"
                                 v-model="spendForm.percentage">
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editSpend = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveSpend">
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Nick Name</th>
                    <td>
                      <div class="row" v-if="!editNickName">
                        <div class="col-md-10">
                          {{ campaign.nickname }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editNickName = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input class="form-control form-control-sm"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter Nick Name"
                                   v-model="nickNameForm.name">
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editNickName = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveNickname">
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="campaign.influencer_campaign_url">
                    <th>Link</th>
                    <td colspan="2">
                      <i class="fa-brands fa-product-hunt"
                         v-if="campaign.influencer_full_link"
                         title="Product Page"></i> {{ getFullLink }}
                      <div class="row">
                        <div class="col-12 text-right">
                          <a class="mr-3"
                             v-if="!campaign.influencer_short_link"
                             href=""
                             @click.prevent="generateShortLink('influencer_short_link')">
                            <i class="fas fa-link"></i> Generate Short Link
                          </a>

                          <!--                          <a class="mr-3"
                                                       v-if="!campaign.influencer_short_link_with_amazon_tag && settings && settings.amazon_associate_tag"
                                                       href=""
                                                       @click.prevent="generateShortLink('influencer_short_link_with_amazon_tag')">
                                                      <i class="fas fa-link"></i> Generate Short Link With Amazon Tag
                                                    </a>-->

                          <a href=""
                             @click.prevent="copyLink(getFullLink)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="campaign.influencer_short_link">
                    <th>Short Link</th>
                    <td colspan="2">
                      <div class="row">
                        <div class="col-6">{{ campaign.influencer_short_link }}</div>
                        <div class="col-6 text-right">
                          <a href=""
                             @click.prevent="copyLink(campaign.influencer_short_link)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Labels</th>
                    <td colspan="2">
                      <div class="row" v-if="!editLabel">
                        <div class="col-md-10">
                           <span class="badge badge-primary mb-1 mr-1"
                                 v-for="label in campaign.labels"
                                 :style="{backgroundColor: label.color}"
                                 :key="'label_'+label.id">{{ label.name }}</span>
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editLabelHandler">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <select class="select2 select-labels"
                                  id="influencer-labels"
                                  style="width: 100%"
                                  multiple="multiple"
                                  v-model="labelForm.labels"
                                  data-placeholder="Select label or enter new...">
                            <option :value="label.name"
                                    v-for="label in labels"
                                    :key="'label_'+label.id">{{ label.name }}</option>
                          </select>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editLabel = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveLabels">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <!--                  <tr v-if="campaign.influencer_short_link_with_amazon_tag">
                                      <th>Short Link With Amazon Tag</th>
                                      <td>
                                        <div class="row">
                                          <div class="col-6">{{ campaign.influencer_short_link_with_amazon_tag }}</div>
                                          <div class="col-6 text-right">
                                            <a href=""
                                               @click.prevent="copyLink(campaign.influencer_short_link_with_amazon_tag)">
                                              <i class="fas fa-copy"></i> Copy
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>-->
                  <tr>
                    <th>Shared With</th>
                    <td colspan="2">
                      <span class="badge badge-info mr-2"
                            v-for="email in campaign.influencer_emails"
                            :key="'email_'+email.id">
                        {{ email.email }}

                        <a class="text-danger"
                           @click.prevent="removeEmail(email.id)"
                           href="#">X</a>
                      </span>

                      <div class="row">
                        <div class="col-12 text-right">
                          <a href=""
                             @click.prevent="addEmail">
                            <i class="fas fa-plus"></i> Add/Share
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div><!--end modal-body-->
        <!--        <div class="modal-footer">-->
        <!--          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import getSymbolFromCurrency from "currency-symbol-map";
import {mapGetters} from "vuex";

export default {
  props: ['currentCurrencyCode', 'id'],
  data() {
    return {
      modal: null,
      campaign: null,
      spendForm: {
        amount: '',
        percentage: ''
      },
      editSpend: false,
      labels: [],
      labelForm: {
        amazon_ad_id: '',
        labels: []
      },
      editLabel: false,
      editNickName: false,
      nickNameForm: {
        id: '',
        name: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    }),
    amount() {
      if (this.campaign) {
        let amount = '';

        if (this.campaign.influencer_amount) {
          amount = getSymbolFromCurrency(this.currentCurrencyCode) + this.campaign.influencer_amount.toFixed(2);
        }


        if (this.campaign.influencer_percentage) {
          if (this.campaign.influencer_amount) {
            amount += ' plus ';
          }

          amount += this.campaign.influencer_percentage + '% of revenue'
        }

        return amount;
      }

      return '';
    },
    getFullLink() {
      if (this.campaign) {
        if (this.campaign.influencer_full_link) {
          return this.campaign.influencer_full_link;
        } else {
          return "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;
        }
      }

      return "";
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById(this.id));
  },
  methods: {
    saveNickname() {
      this.loading = true;

      this.axios.post('/change-nickname', this.nickNameForm)
          .then(() => {
            this.campaign.nickname = this.nickNameForm.name;
            this.editNickName = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    editLabelHandler() {
      this.editLabel = true;

      setTimeout(() => {
        window.$('#influencer-labels').select2({
          tags: true,
          tokenSeparators: [';', '\n', '\t']
        });

        window.$('#influencer-labels').on('select2:closing', function () {
          let $select2 = window.$(this);
          let $searchField = $select2.next().find('.select2-search__field');
          let inputValue = $searchField.val();

          if (inputValue && inputValue.length > 0) {
            $select2.append('<option value="' + inputValue + '" selected="selected">' + inputValue + '</option>');
            $searchField.val('');
            $select2.trigger('change');
          }
        });

        // window.$('#influencer-labels').on('change', () => {
        //   this.labelForm.labels = window.$("#influencer-labels").select2("val");
        // });

        window.$('#influencer-labels').val(this.campaign.labels.map(label => label.name)).change();
      }, 50);
    },
    saveLabels() {
      this.loading = true;
      this.labelForm.labels = window.$("#influencer-labels").select2("val");

      this.axios.post('/labels/update', this.labelForm)
          .then((res) => {
            window.$('.select-labels').select2('destroy');
            window.$('.select-labels').off('change');

            this.editLabel = false;
            this.$store.dispatch('loadLabels')
                .then(res => {
                  this.labels = res.data.data;
                });
            this.campaign.labels = res.data.data;
            this.$emit('labelUpdated', {
              id: this.campaign.id,
              labels: this.campaign.labels
            })
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveSpend() {
      this.allErrors = null;
      this.loading = true;

      this.$swal({
        title: 'Are you sure?',
        text: "Changing these settings will change the Spend data for this campaign retroactively.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, change it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/influencer/change/spend/' + this.campaign.id, this.spendForm)
              .then((res) => {
                this.campaign.influencer_amount = res.data.data.influencer_amount;
                this.campaign.influencer_percentage = res.data.data.influencer_percentage;
                this.editSpend = false;
              })
              .catch((err) => {
                this.allErrors = err.response.data.errors;
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.editSpend = false;
        }
      });
    },
    generateShortLink(column) {
      let url = this.getFullLink;

      if (column === 'influencer_short_link_with_amazon_tag') {
        url += '&tag=' + this.settings.amazon_associate_tag;
      }
      this.axios.post('/short-link/generate', {
        amazon_ad: this.campaign.id,
        column: column,
        url: url
      })
          .then(res => {
            this.campaign[column] = res.data.data[column];
          })
    },
    copyLink(link = null) {
      let url = '';

      if (link)
        url = link;
      else
        url = "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;

      navigator.clipboard.writeText(url);
      this.showSuccessMsg("Link Copied!")
    },
    async addEmail() {
      const {value: email} = await this.$swal({
        title: 'Input email address',
        input: 'text',
        inputPlaceholder: 'Email'
      })

      if (email) {
        this.axios.post('/influencer/email/' + this.campaign.id, {
          email: email
        })
            .then(() => {
              this.loadCampaign(this.campaign.id)
            })
      }
    },
    removeEmail(id) {
      this.$swal({
        title: 'Are you sure want to remove?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, remove it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;

          this.axios.delete('/influencer/email/' + id)
              .then(() => {
                this.loadCampaign(this.campaign.id)
              });
        }
      });
    },
    loadCampaign(campaignId) {
      this.axios.get('/influencer/ad-details', {
        params: {
          campaignId: campaignId
        }
      }).then(async res => {
        this.campaign = res.data

        this.spendForm.percentage = this.campaign.influencer_percentage;
        this.spendForm.amount = this.campaign.influencer_amount;
        this.labelForm.amazon_ad_id = this.campaign.id;
        this.nickNameForm.id = this.campaign.id;
      });
    },
    show(campaignId) {
      this.modal.show();

      this.labels = this.$store.state.labels;

      this.loadCampaign(campaignId);
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>